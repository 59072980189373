<template>
  <div class="record-page">
    <headerBar  :title="i18n.t('record.title')"  :showArrow="true" @back="back"></headerBar>

    <div class="record-main">
      <div class="search-line flex-center-between">
        <div class="time-box" @click="showDate(1)">
          {{form.start || $t('record.label1')}}
        </div>
        <div class="space">-</div>
        <div class="time-box" @click="showDate(2)">
          {{form.end || $t('record.label2')}}
        </div>
        <div class="btn flex-center" @click="search">
          {{i18n.t('record.label3')}}
        </div>
      </div>
      <div class="tabs flex-center">
        <div class="tab" v-for="(item, i) in tabList" :key="i" @click="changeTab(i)" :class="tabIndex == i ? 'active' : ''">
          {{item}}
        </div>
      </div>

      <div class="record-list">
        <List
          v-model="loading"
          :finished="finished"
          :finished-text="i18n.t('common.noMore')"
          @load="onLoad">
          <div class="list flex-center-between" :class="tabIndex === 0 ? 'red' : ''" v-for="(item, i) in orderList" :key="i">
            <div class="time">{{item.CreatedAt}}</div>
            <div class="right" :class="item.Amount > 0 ? 'yellow' : ''">
              <div class="money" v-if="tabIndex != 0">
                ${{item.Amount > 0 ? '+' : ''}}{{parseFloat(parseFloat(item.Amount).toFixed(2))}}
              </div>
              <div class="money" v-else>
                ${{item.Practical > 0 ? '+' : ''}}{{parseFloat(parseFloat(item.Practical || 0).toFixed(2))}}
              </div>
              <div class="status" v-if="tabIndex != 0">{{tabIndex == 1 ? i18n.t('record.label4') : i18n.t('record.label5')}}</div>
              <div class="status" v-else>{{getTypeName(item.Type)}}</div>
            </div>
          </div>
        </List>
      </div>
    </div>

    <Popup v-model:show="showModal" position="bottom">
      <DatetimePicker
        style="width: 100%"
        v-model="currentDate"
        type="date"
        @confirm="changeDate"
        @cancel="cancel"
        :title="title"></DatetimePicker>
    </Popup>
  </div>
</template>
<script>
import { Button, List, Popup, DatetimePicker, Icon, Tabs, Tab } from 'vant'
import headerBar from '@/components/header'
import { getRechargeLog, getWithdrawLog, getDebtType, getDebtLog } from '@/api/user'
import moment from 'moment'
export default {
  components: {
    headerBar,
    Button,
    List,
    Popup,
    DatetimePicker,
    Icon,
    Tabs,
    Tab
  },
  data() {
    return {
      form: {
        start: '',
        end: ''
      },
      currentDate: [moment(), moment()],
      page: {
        page: 1,
        limit: 10
      },
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
      orderList: [ ],
      tabList: [this.i18n.t('record.tab1'), this.i18n.t('record.tab2'), this.i18n.t('record.tab3')],
      title: '',
      showModal: false,
      tabIndex: 0,
      typeObj: {}
    }
  },
  mounted() {
    this.form.start = moment().format('YYYY-MM-DD')
    this.form.end = moment().format('YYYY-MM-DD')
    this.initType()
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    async init(isAdd) {
      this.loading = true
      let form = new FormData()
      form.append('page', this.page.page)
      form.append('limit', this.page.limit)
      form.append('start', this.form.start + ' 00:00:00')
      form.append('end', this.form.end + ' 23:59:59')
      let res = {}
      if (this.tabIndex == 0) {
        res = await getDebtLog(form)
      } else if (this.tabIndex == 1) {
        res = await getWithdrawLog(form)
      } else {
        res = await getRechargeLog(form)
      }
      if (res.data.Items) {
        if (isAdd) {
          this.orderList = this.orderList.concat(res.data.Items)
        } else {
          this.orderList = res.data.Items
        }
        if(res.data.Pagination) {
          this.totalPage = res.data.Pagination.totalPage
          if (this.totalPage <= this.page.page) {
            this.finished = true;
          }
        }

      }
      this.isInit = true
      this.loading = false
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    search() {
      this.page.page = 1
      this.init()
    },
    changeDate(date) {
      if (this.dateType == 1) {
        this.form.start = moment(date).format('YYYY-MM-DD')
      } else {
        this.form.end = moment(date).format('YYYY-MM-DD')
      }
      this.showModal = false
    },
    showDate(type) {
      this.dateType = type
      this.showModal = true
      if (type == 1) {
        this.title = this.i18n.t('record.label1')
      } else {
        this.title = this.i18n.t('record.label2')
      }
    },
    cancel() {
      this.showModal = false
    },
    changeTab(i) {
      this.tabIndex = i ? i : 0
      this.page.page = 1
      this.orderList = []
      this.init()
    },
    initType() {
      getDebtType().then(res => {
        this.typeObj = res.data
      })
    },
    getTypeName(type) {
      let name = this.typeObj[type]
      return name || ''
    }
  }
}
</script>